import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-0 m-0" }
const _hoisted_2 = { class: "p-0 my-2" }
const _hoisted_3 = { class: "grid p-0 m-0 items-container" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "font-semibold m-0 p-0 mt-1 mb-1 inline-block text-sm xl:text-base" }
const _hoisted_6 = { class: "inline receivable-item-value" }
const _hoisted_7 = {
  key: 0,
  class: "col",
  style: { minWidth: '50px' }
}
const _hoisted_8 = { class: "background-bar" }
const _hoisted_9 = { class: "col-12 grid p-0 m-0 receivable-item" }
const _hoisted_10 = { class: "font-semibold m-0 p-0 mt-1 mb-1 inline-block text-sm xl:text-base" }
const _hoisted_11 = { class: "inline receivable-item-value" }
const _hoisted_12 = {
  key: 0,
  class: "col",
  style: { minWidth: '50px' }
}
const _hoisted_13 = { class: "background-bar" }
const _hoisted_14 = {
  class: "hidden xl:flex",
  style: {"display":"flex","justify-content":"flex-end","align-items":"flex-end","width":"100%","margin-top":"10px"}
}
const _hoisted_15 = {
  class: "flex xl:hidden",
  style: {"display":"flex","justify-content":"flex-end","align-items":"flex-end","width":"100%"}
}
const _hoisted_16 = {
  key: 1,
  class: "receivable-container pl-2"
}
const _hoisted_17 = { class: "p-0 my-2" }
const _hoisted_18 = { class: "grid p-0 m-0" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "font-semibold m-0 p-0 mt-1 mb-1 inline-block text-sm" }
const _hoisted_21 = { class: "inline receivable-item-value" }
const _hoisted_22 = {
  key: 0,
  class: "col",
  style: { minWidth: '50px' }
}
const _hoisted_23 = { class: "background-bar" }
const _hoisted_24 = {
  key: 0,
  class: "grid p-0 m-0"
}
const _hoisted_25 = { class: "col-12 grid p-0 m-0 receivable-item" }
const _hoisted_26 = { class: "font-semibold m-0 p-0 mt-1 mb-1 inline-block text-sm xl:text-base" }
const _hoisted_27 = { class: "inline receivable-item-value" }
const _hoisted_28 = {
  key: 0,
  class: "col",
  style: { minWidth: '50px' }
}
const _hoisted_29 = { class: "background-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showInCard)
      ? (_openBlock(), _createBlock(_component_Card, {
          key: 0,
          class: "receivable-container"
        }, _createSlots({
          header: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["flex receivable-title", {
            'justify-content-center': _ctx.titleAlign === 'center',
            'justify-content-left': _ctx.titleAlign === 'left',
          }])
                }, [
                  _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
                ], 2))
              : _createCommentVNode("", true)
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.label,
                  class: _normalizeClass(["col-12 grid p-0 m-0 receivable-item", { 'selected-receivable-item': index === _ctx.selectedIndex }]),
                  onClick: ($event: any) => (_ctx.handleRowClick(item, index))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-0 m-0 receivable-item-label", { 'col-4': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                    style: { minWidth: '72px' }
                  }, [
                    _createElementVNode("div", {
                      style: _normalizeStyle({
                  backgroundColor: item.color ? item.color : 'lightgray',
                }),
                      class: _normalizeClass(["circle mr-1", { 'mr-3': !_ctx.showBars }])
                    }, null, 6),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(item.label) + ": ", 1)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-0 m-0 text-right pr-1", { 'col-2': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                    style: { minWidth: '50px' }
                  }, [
                    _createElementVNode("p", _hoisted_6, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-0 m-0 text-xs xl:text-base", { 'font-semibold': true }])
                      }, _toDisplayString(_ctx.formatValue(item.value)), 1)
                    ])
                  ], 2),
                  (_ctx.showBars)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", {
                            style: _normalizeStyle({
                    width: _ctx.calculateBarWidth(item.value, _ctx.total.value) + '%',
                    backgroundColor: item.color,
                  }),
                            class: "bar"
                          }, null, 4)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_4))
              }), 128))
            ])
          ]),
          _: 2
        }, [
          (_ctx.showTotal)
            ? {
                name: "footer",
                fn: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "grid p-0 m-0",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRowClick({ label: '' }, -1)))
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["p-0 m-0 receivable-item-label", { 'col-4': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                        style: { minWidth: '72px' }
                      }, [
                        _createElementVNode("div", {
                          style: _normalizeStyle({
                  backgroundColor: _ctx.total.color ? _ctx.total.color : 'lightgray',
                }),
                          class: _normalizeClass(["circle mr-1", { 'mr-3': !_ctx.showBars }])
                        }, null, 6),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.total.label) + ": ", 1)
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["p-0 m-0 text-right pr-1", { 'col-2': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                        style: { minWidth: '50px' }
                      }, [
                        _createElementVNode("p", _hoisted_11, [
                          _createElementVNode("span", {
                            class: _normalizeClass(["p-0 m-0 text-xs xl:text-base", { 'font-semibold': true }])
                          }, _toDisplayString(_ctx.formatValue(_ctx.total.value)), 1)
                        ])
                      ], 2),
                      (_ctx.showBars)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", {
                                style: _normalizeStyle({
                    width: _ctx.calculateBarWidth(_ctx.total.value, _ctx.total.value) + '%',
                    backgroundColor: _ctx.total.color,
                  }),
                                class: "bar"
                              }, null, 4)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      (!_ctx.disable_downloading_statements)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            icon: "pi pi-download",
                            class: "ml-1 my-1",
                            label: "Download Statement",
                            onClick: _ctx.handleDownloadClick,
                            loading: _ctx.loadingStatement
                          }, null, 8, ["onClick", "loading"]))
                        : _createCommentVNode("", true),
                      (!_ctx.disable_emailing_statements)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 1,
                            icon: "pi pi-envelope",
                            label: "Email Statement",
                            class: "ml-1 my-1",
                            onClick: _ctx.handleEmailClick,
                            loading: _ctx.emailLoading
                          }, null, 8, ["onClick", "loading"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      (!_ctx.disable_downloading_statements)
                        ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            icon: "pi pi-download",
                            text: "",
                            rounded: "",
                            onClick: _ctx.handleDownloadClick,
                            loading: _ctx.loadingStatement
                          }, null, 8, ["onClick", "loading"])), [
                            [
                              _directive_tooltip,
                              'Download Statement',
                              void 0,
                              { left: true }
                            ]
                          ])
                        : _createCommentVNode("", true),
                      (!_ctx.disable_emailing_statements)
                        ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                            key: 1,
                            icon: "pi pi-envelope",
                            text: "",
                            rounded: "",
                            onClick: _ctx.handleEmailClick,
                            loading: _ctx.emailLoading
                          }, null, 8, ["onClick", "loading"])), [
                            [
                              _directive_tooltip,
                              'Email Statement',
                              void 0,
                              { left: true }
                            ]
                          ])
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024))
      : (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex receivable-title", {
          'justify-content-center': _ctx.titleAlign === 'center',
          'justify-content-left': _ctx.titleAlign === 'left',
        }])
          }, [
            _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.title), 1)
          ], 2),
          _createElementVNode("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.label,
                class: "col-12 grid p-0 m-0 receivable-item"
              }, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["p-0 m-0 receivable-item-label", { 'col-4': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                  style: { minWidth: '72px' },
                  onClick: ($event: any) => (_ctx.handleRowClick(item, -1))
                }, [
                  _createElementVNode("div", {
                    style: _normalizeStyle({
                backgroundColor: item.color ? item.color : 'lightgray',
              }),
                    class: _normalizeClass(["circle mr-1", { 'mr-3': !_ctx.showBars }])
                  }, null, 6),
                  _createElementVNode("p", _hoisted_20, _toDisplayString(item.label) + ": ", 1)
                ], 10, _hoisted_19)), [
                  [_directive_tooltip, {
              showDelay: 500,
              value: _ctx.getToolTipTitle(item.label),
              pt: {
                text: 'text-sm',
              },
            }, "bottom"]
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["p-0 m-0 text-right pr-1", { 'col-2': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                  style: { minWidth: '50px' }
                }, [
                  _createElementVNode("p", _hoisted_21, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["p-0 m-0 text-sm", { 'font-semibold': true }])
                    }, _toDisplayString(_ctx.formatValue(item.value)), 1)
                  ])
                ], 2),
                (_ctx.showBars)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", {
                          style: _normalizeStyle({
                  width: _ctx.calculateBarWidth(item.value, _ctx.total.value) + '%',
                  backgroundColor: item.color,
                }),
                          class: "bar"
                        }, null, 4)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          (_ctx.showTotal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-0 m-0 receivable-item-label", { 'col-4': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                    style: { minWidth: '72px' }
                  }, [
                    _createElementVNode("div", {
                      style: _normalizeStyle({
                backgroundColor: _ctx.total.color ? _ctx.total.color : 'lightgray',
              }),
                      class: _normalizeClass(["circle mr-1", { 'mr-3': !_ctx.showBars }])
                    }, null, 6),
                    _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.total.label) + ": ", 1)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-0 m-0 text-right pr-1", { 'col-2': _ctx.showBars, 'col-6': !_ctx.showBars }]),
                    style: { minWidth: '50px' }
                  }, [
                    _createElementVNode("p", _hoisted_27, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-0 m-0 text-xs xl:text-base", { 'font-semibold': true }])
                      }, _toDisplayString(_ctx.formatValue(_ctx.total.value)), 1)
                    ])
                  ], 2),
                  (_ctx.showBars)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("div", {
                            style: _normalizeStyle({
                  width: _ctx.calculateBarWidth(_ctx.total.value, _ctx.total.value) + '%',
                  backgroundColor: _ctx.total.color,
                }),
                            class: "bar"
                          }, null, 4)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}