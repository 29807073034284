
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Card from "primevue/card";
import Utils from "@/utility/utils";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";

export default defineComponent({
  name: "Receivables",
  components: {
    Card,
    Button,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    title: {
      type: String,
    },
    items: Array<any>(),
    showBars: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    roundTotals: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Object,
      default: () => ({ color: "lightgray", label: "Total", value: "0" }),
    },
    showInCard: {
      type: Boolean,
      default: true,
    },
    titleAlign: {
      type: String,
      default: "center",
    },
    selectedIdx: {
      type: Number,
      default: -1,
    },
    toolTipTitle: {
      type: String,
      default: "",
    },
    loadingStatement: {
      type: Boolean,
      default: false,
    },
    emailLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["row:click", "download:statement", "email:statement"],
  data() {
    return {
      selectedIndex: -1,
    };
  },
  computed: {
    ...mapGetters({
      disable_downloading_statements: "mrkControl/disableDownloadingStatements",
      disable_emailing_statements: "mrkControl/disableEmailingStatements",
    }),
  },
  methods: {
    getToolTipTitle(label: any) {
      return this.toolTipTitle
        ? this.toolTipTitle
        : `Click to see "${label}" records`;
    },
    handleRowClick(data: any, index: number) {
      this.selectedIndex = index;
      this.$emit("row:click", { ...data });
    },
    handleDownloadClick() {
      this.$emit("download:statement");
    },
    handleEmailClick() {
      this.$emit("email:statement");
    },
    formatValue(value: string) {
      return Utils.formatAmount(value, this.roundTotals, this.roundTotals);
    },
    calculateBarWidth(value: string, totalValue: string) {
      const numericValue = parseFloat(value) || 0;
      const maxValue = this.showTotal
        ? parseFloat(totalValue) || 1
        : Math.max(
            ...this.items.map((item: any) => parseFloat(item.value) || 0),
          );
      return (numericValue / maxValue) * 100;
    },
  },

  watch: {
    selectedIdx(idx: number) {
      this.selectedIndex = idx;
    },
  },
});
